import { css as e } from "styled-components";
import { r as d } from "../../polished.esm-B6VUs5va.js";
import "../Theme/themes.es.js";
import "../../palettes-Dfhobg_2.js";
import { getPaletteColors as t, getPaletteBackground as c, getRadii as i, getPaletteBase as a, getSpacings as n, getPaletteBorder as l } from "../Theme/utils.es.js";
import "react";
import "../../jsx-runtime-DmTjM30f.js";
import "../../CssBaseline-E-zwSHrJ.js";
const v = {
  sm: e`
    height: 36px;
  `,
  md: e`
    height: 44px;
  `
}, r = {
  ghost: e`
    color: ${(o) => t(o.$color)(o)[700]};
    background-color: ${(o) => t(o.$color)(o)[50]};
  `,
  shadow: e`
    color: ${(o) => t(o.$color)(o)[700]};
    background-color: ${c("primary.default")};

    &:not(:focus) {
      box-shadow:
        0px 1px 3px 0px ${(o) => d(t(o.$color)(o)[900], 0.1)},
        0px 1px 2px 0px ${(o) => d(t(o.$color)(o)[900], 0.06)};
    }
  `,
  lined: e`
    color: ${(o) => t(o.$color)(o)[700]};
    border-color: ${(o) => t(o.$color)(o)[600]};
  `,
  "lined-ghost": e`
    color: ${(o) => t(o.$color)(o)[700]};
    border-color: ${(o) => t(o.$color)(o)[600]};
    background-color: ${(o) => t(o.$color)(o)[50]};
  `
}, f = {
  ghost: e`
    border: none;
    
    border-radius: ${i("sm")};
    
    ${(o) => o.$isSelected ? r.ghost : void 0}

    &:hover:enabled, &:active:enabled {
      ${r.ghost}
    }
    
    &:focus {
      box-shadow: 0px 0px 0px 4px ${(o) => d(t(o.$color)(o)[500], 0.24)};
    }
    
  `,
  shadow: e`
    border: none;

    border-radius: ${i("sm")};

    ${(o) => o.$isSelected ? r.shadow : void 0}
    
    &:hover:enabled, &:active:enabled {
      ${r.shadow};
    }
    
    &:focus {
      box-shadow:
        0px 1px 3px 0px ${(o) => d(t(o.$color)(o)[900], 0.1)},
        0px 1px 2px 0px ${(o) => d(t(o.$color)(o)[900], 0.06)},
        0px 0px 0px 4px ${(o) => d(t(o.$color)(o)[400], 0.14)};
    }
  `,
  lined: e`
    border: none;
    
    ${(o) => o.$orientation === "vertical" ? "border-left" : "border-bottom"}: 
      2px solid ${a("transparent")};

    ${(o) => o.$isSelected ? r.lined : void 0}
    
    &:hover:enabled, &:active:enabled {
      ${r.lined}
    }
  `,
  "lined-ghost": e`
    border: none;

    ${(o) => o.$orientation === "vertical" ? "border-left" : "border-bottom"}:
      2px solid ${a("transparent")};

    ${(o) => o.$isSelected ? r["lined-ghost"] : void 0}
    
    &:hover:enabled, &:active:enabled {
      ${r["lined-ghost"]}
    }
  `
}, u = {
  horizontal: e``,
  vertical: e`
    width: 100%;
    justify-content: flex-start;
  `
}, y = {
  ghost: e`
    padding: ${n("xs")};
  `,
  shadow: e`
    padding: ${n("xs")};
    border-radius: ${i("lg")};
    border: 1px solid ${l("secondary")};
    background-color: ${c("secondary.alt")};
  `,
  lined: e`
    gap: 0px;
    
    ${(o) => o.$orientation === "vertical" ? "border-left" : "border-bottom"}:
      1px solid ${l("secondary")};
  `,
  "lined-ghost": e`
    gap: 0px;
    
    ${(o) => o.$orientation === "vertical" ? "border-left" : "border-bottom"}:
      1px solid ${l("secondary")};
  `
}, S = {
  horizontal: e`
    width: 100%;
    flex-direction: row;
  `,
  vertical: e`
    flex-direction: column;
  `
};
export {
  S as tabListOrientationStyles,
  y as tabListVariantStyles,
  u as tabOrientationStyles,
  v as tabSizeStyles,
  f as tabVariantStyles
};
