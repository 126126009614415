import { j as o } from "../../jsx-runtime-DmTjM30f.js";
import s from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-Dfhobg_2.js";
import { getPaletteBackground as c, getSpacings as i, getPaletteBorder as d } from "../Theme/utils.es.js";
import "react";
import "../../CssBaseline-E-zwSHrJ.js";
import { S as m, u as p } from "../../hooks-WvzGrZsH.js";
import { I as x } from "../../Icon-BJLMcRlb.js";
import "../Icon/countryVariants.es.js";
import { I as f } from "../../IconButton-BUDydTIR.js";
const g = s.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  
  background: ${c("primary.default")};
`, P = (e) => {
  const {
    isOpen: t,
    onClose: n,
    ...r
  } = e;
  return /* @__PURE__ */ o.jsx(
    m,
    {
      isOpen: t,
      onClose: n,
      children: t && /* @__PURE__ */ o.jsx(g, { ...r })
    }
  );
}, u = s.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  height: 64px;
  
  padding: ${i("lg")} ${i("xl")};
  gap: ${i("lg")};
  
  border-bottom: 1px solid ${d("secondary")};
  width: 100%;
`, z = (e) => {
  const {
    children: t,
    className: n,
    onBack: r
  } = e, { onClose: a } = p(), l = () => {
    r ? r() : a && a();
  };
  return /* @__PURE__ */ o.jsxs(u, { className: n, children: [
    /* @__PURE__ */ o.jsx(
      f,
      {
        variant: "outlined",
        color: "gray",
        onClick: l,
        children: /* @__PURE__ */ o.jsx(
          x,
          {
            variant: "arrow-left"
          }
        )
      }
    ),
    t
  ] });
}, h = s.div`
  display: flex;
  flex: 1;
  overflow: auto;
`, E = (e) => /* @__PURE__ */ o.jsx(h, { ...e });
export {
  E as ScreenBody,
  z as ScreenHeader,
  P as default
};
