import { j as i } from "./jsx-runtime-DmTjM30f.js";
import d from "styled-components";
import "./styles-CuuuEl0j.js";
import { T as c } from "./Typography-DPoSDQ1T.js";
import "./components/Theme/themes.es.js";
import "./palettes-Dfhobg_2.js";
import { getPaletteColors as r, getRadii as p, getSpacings as g } from "./components/Theme/utils.es.js";
import "react";
import "./CssBaseline-E-zwSHrJ.js";
import { badgeSizeStyles as x } from "./components/Badge/styles.es.js";
const $ = d.div`
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  
  background: ${(o) => r(o.$color)(o)[50]};
  color: ${(o) => r(o.$color)(o)[700]};
  
  border-radius: ${p("full")};
  border: 1px solid ${(o) => r(o.$color)(o)[200]};
  gap: ${g("xs")};
  
  ${(o) => x[o.$size]};
`, C = (o) => {
  const {
    text: e,
    color: s = "brand",
    size: t = "sm",
    className: n,
    startAdornment: m,
    endAdornment: l
  } = o, a = t === "sm" ? "xs" : "sm";
  return /* @__PURE__ */ i.jsxs(
    $,
    {
      className: n,
      $color: s,
      $size: t,
      children: [
        m,
        !!e && /* @__PURE__ */ i.jsx(
          c,
          {
            size: a,
            weight: "medium",
            color: "none",
            children: e
          }
        ),
        l
      ]
    }
  );
};
export {
  C as B
};
