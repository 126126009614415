import { j as e } from "./jsx-runtime-DmTjM30f.js";
import l from "styled-components";
import { forwardRef as E, useState as K, useEffect as N, useRef as j } from "react";
import "./components/Theme/themes.es.js";
import "./palettes-Dfhobg_2.js";
import { getPaletteBackground as v, getPaletteBase as W, getSpacings as u, getRadii as k, getPaletteForeground as O, getPaletteBorder as _, getEffects as P } from "./components/Theme/utils.es.js";
import "./CssBaseline-E-zwSHrJ.js";
import { Menu as $ } from "@headlessui/react";
import { createPortal as H } from "react-dom";
import "./styles-CuuuEl0j.js";
import { T as D } from "./Typography-DPoSDQ1T.js";
import { I as A } from "./Icon-BJLMcRlb.js";
import "./components/Icon/countryVariants.es.js";
import { B as U } from "./TextField-vJ13tb4d.js";
import { u as q } from "./useAppFloating-CuhQeRN4.js";
const Y = l(D)`
  flex: 1;
  text-align: left;
`, G = (t) => /* @__PURE__ */ e.jsx(
  Y,
  {
    size: "md",
    weight: "medium",
    ...t
  }
), J = l.button`
  display: inline-flex;
  align-items: center;
  width: 100%;
  outline: none;
  border: none;
  
  background: ${(t) => t.$isActive ? v("active") : W("transparent")};

  padding: 10px 10px 10px ${u("md")};
  gap: ${u("md")};
  border-radius: ${k("sm")};
  
  &:hover {
    background: ${v("active")};
  }
  
  &[aria-disabled="true"] {
    cursor: not-allowed;
    background: ${v("disabled.subtle")};
  }
`, Q = l(A)`
  color: ${O("brand.primary.default")};
`, X = (t) => {
  const {
    isSelected: c,
    disabled: i,
    children: r,
    ...s
  } = t;
  return /* @__PURE__ */ e.jsx(
    $.Item,
    {
      disabled: i,
      ...s,
      children: ({ active: a }) => /* @__PURE__ */ e.jsxs(J, { $isActive: a, children: [
        typeof r == "string" ? /* @__PURE__ */ e.jsx(
          G,
          {
            color: t.disabled ? "disabled" : void 0,
            children: r
          }
        ) : r,
        c && /* @__PURE__ */ e.jsx(
          Q,
          {
            width: 20,
            height: 20,
            variant: "check"
          }
        )
      ] })
    }
  );
}, Z = l.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`, ee = l($.Items)`
  box-sizing: border-box;
  max-height: 320px;
  overflow-y: auto;
  
  width: ${(t) => t.$width ? `${t.$width}px` : "initial"};
  
  padding: ${u("xs")} ${u("sm")};
  background: ${v("primary.default")};
  border-radius: ${k("md")};
  border: 1px solid ${_("secondary")};
  box-shadow: ${P("shadows.lg")};

  margin-top: ${u("xs")};
  margin-bottom: ${u("xs")};
`, te = E(function(c, i) {
  const {
    textFieldRef: r,
    children: s,
    ...a
  } = c, [
    p,
    m
  ] = K(0);
  return N(() => {
    var g;
    const f = (g = r == null ? void 0 : r.current) == null ? void 0 : g.parentElement;
    if (!f)
      return;
    const x = new ResizeObserver((y) => {
      const [S] = y, [h] = S.borderBoxSize;
      m(h.inlineSize);
    });
    return x.observe(f), () => {
      x.disconnect();
    };
  }, []), /* @__PURE__ */ e.jsx(
    ee,
    {
      ref: i,
      $width: p,
      ...a,
      children: /* @__PURE__ */ e.jsx(Z, { children: s })
    }
  );
}), ne = l(U)`
  &,
  > * {
    cursor: pointer;
  }
  ::selection {
    color: none;
    background-color: transparent;
  }
  box-shadow: ${(t) => t.$isActive ? P("focusRings.brand.xs")(t) : "none"};
`, oe = l(A)`
  display: ${(t) => t.$isDropDownIconVisible ? "inline" : "none"};
  &:hover {
    color: ${O("quarterary.hover")};
  }
`, re = l.div`
  display: inline-flex;
  gap: ${u("md")};
`, ie = E(
  function(c, i) {
    const {
      endAdornment: r,
      containerProps: s,
      selectedOptionLabel: a = "",
      isMenuContextOpen: p = !1,
      isDropDownIconVisible: m = !0,
      ...f
    } = c;
    return /* @__PURE__ */ e.jsx(
      ne,
      {
        ref: i,
        value: a,
        $isActive: p,
        endAdornment: /* @__PURE__ */ e.jsxs(re, { children: [
          r,
          /* @__PURE__ */ e.jsx(
            oe,
            {
              width: 20,
              height: 20,
              variant: "chevron-down",
              $isDropDownIconVisible: m
            }
          )
        ] }),
        containerProps: s,
        ...f
      }
    );
  }
), se = (t, c) => {
  const { label: i, value: r, onSelect: s, isSelected: a, ...p } = t, m = () => {
    s(r);
  };
  return /* @__PURE__ */ e.jsx(
    X,
    {
      onClick: m,
      isSelected: a,
      ...p,
      children: i
    },
    c
  );
}, le = l.input`
  display: none;
`, ce = l($.Button)`
  display: none;
`, ae = l.div`
  padding: ${u("md")};
`, de = l.div`
  padding: ${u("md")};
`, ue = ["Enter", "ArrowUp", "ArrowDown", " "], Ce = (t) => {
  const {
    value: c,
    options: i,
    onClick: r,
    inputRef: s,
    onChange: a,
    emptyContent: p,
    isLoading: m = !1,
    isDropDownIconVisible: f = !0,
    renderSelectItem: x = se,
    ...g
  } = t, {
    refs: y,
    floatingStyles: S
  } = q({
    floatingOptions: {
      placement: "bottom"
    },
    offset: 5
  }), h = j(null), I = j(null), b = j(null), w = i.find((o) => o.value === c), B = (o) => {
    var n;
    t.disabled || (r && r(o), (n = h.current) == null || n.click());
  }, L = (o) => {
    var d;
    const n = (d = Object.getOwnPropertyDescriptor(
      HTMLInputElement.prototype,
      "value"
    )) == null ? void 0 : d.set;
    if (b.current && n) {
      n.call(b.current, o);
      const C = new Event("input", {
        bubbles: !0
      });
      b.current.dispatchEvent(C);
    }
  }, F = (o) => {
    if (!a)
      return;
    const n = o.target.value, d = i.find(
      (M) => String(M.value) === n
    );
    if (!d)
      throw new Error(
        `Failed to map selected value from option: ${d}`
      );
    c !== d.value && a(o, d.value);
  }, R = () => i.map((o, n) => {
    const d = t.value === o.value;
    return x(
      {
        ...o,
        onSelect: L,
        isSelected: d
      },
      n
    );
  }), T = (o) => {
    var n;
    ue.includes(o.key) && ((n = h.current) == null || n.click());
  }, V = i.length === 0, z = p || /* @__PURE__ */ e.jsx(ae, { children: /* @__PURE__ */ e.jsx(D, { color: "secondary", children: "No data" }) });
  return /* @__PURE__ */ e.jsx($, { children: (o) => /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
    /* @__PURE__ */ e.jsx(ce, { ref: h }),
    /* @__PURE__ */ e.jsx(
      le,
      {
        ref: (n) => {
          typeof s == "function" ? s(n) : s && (s.current = n), b.current = n;
        },
        onChange: F,
        value: c ?? ""
      }
    ),
    /* @__PURE__ */ e.jsx(
      ie,
      {
        readOnly: !0,
        ref: I,
        onKeyDown: T,
        isMenuContextOpen: o.open,
        selectedOptionLabel: w == null ? void 0 : w.label,
        isDropDownIconVisible: f,
        containerProps: {
          onClick: B,
          ref: y.setReference
        },
        ...g
      }
    ),
    H(
      /* @__PURE__ */ e.jsx(
        te,
        {
          ref: y.setFloating,
          style: S,
          textFieldRef: I,
          children: m ? /* @__PURE__ */ e.jsx(de, { children: /* @__PURE__ */ e.jsx(D, { color: "secondary", children: "Loading..." }) }) : /* @__PURE__ */ e.jsx(e.Fragment, { children: V ? z : R() })
        }
      ),
      document.body
    )
  ] }) });
};
export {
  Ce as B,
  X as S,
  G as a
};
