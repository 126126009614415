import { j as r } from "./jsx-runtime-DmTjM30f.js";
import e from "styled-components";
import { getDividerStyles as s } from "./components/Divider/styles.es.js";
const a = e.div`
  ${(t) => s(t.$orientation, t.$spacing)}
`, $ = (t) => {
  const {
    className: i,
    spacing: o = "none",
    orientation: n = "horizontal"
  } = t;
  return /* @__PURE__ */ r.jsx(
    a,
    {
      $spacing: o,
      className: i,
      $orientation: n
    }
  );
};
export {
  $ as D
};
