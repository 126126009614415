import { j as o } from "./jsx-runtime-DmTjM30f.js";
import t from "styled-components";
import "./components/Theme/themes.es.js";
import "./palettes-Dfhobg_2.js";
import { getRadii as $, getPaletteBorder as u, getPaletteBase as g, getPaletteColors as a, getPaletteBackground as h, getPaletteForeground as b, getSpacings as f } from "./components/Theme/utils.es.js";
import { useRef as y } from "react";
import "./CssBaseline-E-zwSHrJ.js";
import { a as k } from "./Switch-DJB6eakx.js";
import { r as x } from "./polished.esm-B6VUs5va.js";
import { I as z } from "./Icon-BJLMcRlb.js";
import "./components/Icon/countryVariants.es.js";
import { sizeStyles as j, iconSizeStyles as C, inputSizeStyles as S } from "./components/Checkbox/styles.es.js";
const P = t.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  
  border-radius: ${$("xs")};
  border: 1px solid ${u("primary")};
  background-color: ${g("transparent")};
  
  ${(e) => j[e.$size]};

  &:focus:not(:disabled) {
    box-shadow: 0px 0px 0px 4px ${(e) => x(a("gray.400")(e), 0.14)};
  }
  
  &[aria-checked="true"], &[data-indeterminate="true"] {
    background-color: ${(e) => a(e.$color)(e)[600]};
    border-color: ${(e) => a(e.$color)(e)[600]};
    
    &:focus {
      box-shadow: 0px 0px 0px 4px ${(e) => x(a(e.$color)(e)[500], 0.24)};
    }
  }
  
  &:disabled {
    background-color: ${h("disabled.subtle")};
    border-color: ${u("disabled.subtle")};
  }
`, w = t.div`
  display: inline-flex;
  
  color: ${b("white")};

  &[aria-disabled="true"] {
    color: ${b("disabled.subtle")};
  }
`, B = t(z)`
  ${(e) => C[e.$size]};
`, I = t.input`
  position: absolute;
  opacity: 0;
  
  ${(e) => S[e.$size]};

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }
`, v = (e) => {
  const {
    size: r = "md",
    color: n = "brand",
    checked: i = !0,
    indeterminate: s,
    onFocus: d,
    ...l
  } = e, c = y(null), m = (p) => {
    c.current && c.current.focus(), d && d(p);
  };
  return /* @__PURE__ */ o.jsxs(
    P,
    {
      $size: r,
      $color: n,
      "aria-checked": i,
      "data-indeterminate": s,
      disabled: l.disabled,
      ref: c,
      children: [
        /* @__PURE__ */ o.jsx(
          I,
          {
            $size: r,
            type: "checkbox",
            checked: i,
            onFocus: m,
            ...l
          }
        ),
        (e.indeterminate || e.checked) && /* @__PURE__ */ o.jsx(
          w,
          {
            "aria-disabled": e.disabled,
            children: /* @__PURE__ */ o.jsx(
              B,
              {
                $size: r,
                variant: s ? "minus" : "check"
              }
            )
          }
        )
      ]
    }
  );
}, F = t.div`
  display: flex;
  width: 344px;

  gap: ${f("md")};
`, O = (e) => {
  const {
    label: r,
    helperMessage: n,
    errorMessage: i,
    ...s
  } = e;
  return /* @__PURE__ */ o.jsxs(F, { children: [
    /* @__PURE__ */ o.jsx(v, { ...s }),
    /* @__PURE__ */ o.jsx(
      k,
      {
        label: r,
        helperMessage: n,
        errorMessage: i
      }
    )
  ] });
};
export {
  v as B,
  O as C
};
