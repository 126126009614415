import { useSearchParams as i } from "react-router-dom";
import { useState as P } from "react";
import { c as Q } from "../../index.module-CodhwjS0.js";
const f = 200, E = (p) => {
  const {
    contextMapper: u,
    persistDelay: y = f
  } = p, [
    l,
    S
  ] = i(), c = Q(S, y), o = Object.keys(u).reduce((r, t) => {
    const e = t, s = u[e], a = l.get(s);
    return {
      ...r,
      [e]: a || ""
    };
  }, {}), [
    V,
    n
  ] = P(o);
  return {
    values: V,
    queryValues: o,
    setQueryValue: (r, t) => {
      const e = u[r];
      n((s) => (c((a) => ({
        ...a,
        [e]: t
      })), {
        ...s,
        [r]: t
      }));
    },
    setQueryValues: (r) => {
      const t = Object.entries(u).reduce((e, s) => {
        const [
          a,
          d
        ] = s, m = r[a];
        return m ? {
          ...e,
          [d]: m
        } : e;
      }, {});
      n((e) => (c(t), {
        ...e,
        ...r
      }));
    }
  };
};
export {
  E as useQueryParameters
};
