import { j as o } from "./jsx-runtime-DmTjM30f.js";
import a from "styled-components";
import "./components/Theme/themes.es.js";
import "./palettes-Dfhobg_2.js";
import { getRadii as b, getPaletteBorder as m, getPaletteBackground as h, getBreakpointMediaQuery as u, getSpacings as d } from "./components/Theme/utils.es.js";
import { forwardRef as f } from "react";
import "./CssBaseline-E-zwSHrJ.js";
import { T as $, u as x } from "./hooks-C3xS-Knp.js";
import "./styles-CuuuEl0j.js";
import { T as g } from "./Typography-DPoSDQ1T.js";
import { N as y } from "./ErrorFeedbackContainer-HdbjEit2.js";
import { B as T } from "./Button-iQ12_5TW.js";
const j = a.div`
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  overflow: hidden;
  box-sizing: border-box;
  
  border-radius: ${b("xl")};
  border: 1px solid ${m("secondary")};
  background: ${h("primary.default")};
  
  ${u("max", "sm")} {
    border-radius: 0;
  }
`, w = (e) => {
  const {
    children: t,
    className: r
  } = e;
  return /* @__PURE__ */ o.jsx($, { children: /* @__PURE__ */ o.jsx(j, { className: r, children: t }) });
}, C = a.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`, v = f(function(t, r) {
  const {
    className: l,
    children: i
  } = t, { headerRef: n } = x(), s = (c) => {
    n.current && (n.current.scrollLeft = c.currentTarget.scrollLeft);
  };
  return /* @__PURE__ */ o.jsx(
    C,
    {
      className: l,
      ref: r,
      onScroll: s,
      children: i
    }
  );
}), k = a.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  box-sizing: border-box;
  min-width: 0;
  overflow: hidden;
  
  flex: ${(e) => e.$flex};

  padding: ${d("xl")} ${d("3xl")};
  border-bottom: 1px solid ${m("secondary")};
  
`, B = (e) => {
  const {
    className: t,
    span: r = "1",
    minWidth: l = "0",
    size: i = "0",
    children: n
  } = e, s = `${r} ${l} ${i}`;
  return /* @__PURE__ */ o.jsx(
    k,
    {
      className: t,
      $flex: s,
      children: n
    }
  );
}, R = a.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  overflow-x: hidden;
`, O = (e) => {
  const { headerRef: t } = x();
  return /* @__PURE__ */ o.jsx(R, { ref: t, children: e.children });
}, N = a(B)`
  padding: ${d("lg")} ${d("3xl")};
  background: ${h("secondary.default")};
`, U = (e) => {
  const {
    children: t,
    ...r
  } = e;
  return /* @__PURE__ */ o.jsx(N, { ...r, children: typeof t == "string" ? /* @__PURE__ */ o.jsx(
    g,
    {
      color: "tertiary",
      size: "xs",
      weight: "medium",
      children: t
    }
  ) : t });
}, z = a.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  
  &:hover {
    > div {
      background: ${h("primary.hover")};
      cursor: ${(e) => e.$isClickable ? "pointer" : "default"};
    }
  }
`, S = f(function(t, r) {
  const {
    className: l,
    children: i,
    isClickable: n,
    ...s
  } = t;
  return /* @__PURE__ */ o.jsx(
    z,
    {
      ref: r,
      className: l,
      $isClickable: n,
      ...s,
      children: i
    }
  );
}), F = a.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  
  gap: ${d("3xl")};
`, P = a(y)`
  width: 352px;
  height: unset;

  ${u("max", "lg")} {
    width: 100%;
  }
`, X = (e) => {
  const {
    title: t,
    message: r,
    className: l,
    hasFilter: i,
    customActionButton: n,
    onClearFilterClick: s
  } = e, c = r || (i ? "Adjust your search criteria or clear the filters and try again." : "It's looking a bit empty here.");
  return /* @__PURE__ */ o.jsx(w, { children: /* @__PURE__ */ o.jsx(F, { className: l, children: /* @__PURE__ */ o.jsx(
    P,
    {
      title: t,
      message: c,
      customActionButton: n,
      children: i && /* @__PURE__ */ o.jsx(
        T,
        {
          variant: "outlined",
          color: "gray",
          onClick: s,
          children: /* @__PURE__ */ o.jsx(g, { size: "md", weight: "semibold", color: "secondary", children: "Clear filters" })
        }
      )
    }
  ) }) });
}, V = a.div`
  flex: 1 1 0;
  overflow: auto;
  width: 100%;
  scrollbar-width: thin;
`, E = a(v)`
  position: relative;
  height: ${(e) => e.$height}px;
`, Z = f(
  function(t, r) {
    const {
      totalHeight: l,
      children: i,
      className: n
    } = t, { headerRef: s } = x(), c = (p) => {
      s.current && (s.current.scrollLeft = p.currentTarget.scrollLeft);
    };
    return /* @__PURE__ */ o.jsx(
      V,
      {
        ref: r,
        className: n,
        onScroll: c,
        children: /* @__PURE__ */ o.jsx(E, { $height: l, children: i })
      }
    );
  }
), L = a(S).attrs((e) => ({
  style: {
    transform: `translateY(${e.$start}px)`
  }
}))`
  position: absolute;
  width: 100%;
`, _ = (e) => {
  const {
    children: t,
    offset: r,
    index: l,
    measureElement: i,
    ...n
  } = e;
  return /* @__PURE__ */ o.jsx(
    L,
    {
      ref: (s) => i(s),
      $start: r,
      "data-index": l,
      ...n,
      children: t
    }
  );
};
export {
  X as E,
  w as T,
  Z as V,
  v as a,
  B as b,
  O as c,
  U as d,
  S as e,
  _ as f
};
