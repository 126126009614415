import { css as e, keyframes as x } from "styled-components";
import { r } from "../../polished.esm-B6VUs5va.js";
import "../Theme/themes.es.js";
import "../../palettes-Dfhobg_2.js";
import { getPaletteBackground as a, getPaletteColors as o } from "../Theme/utils.es.js";
import "react";
import "../../jsx-runtime-DmTjM30f.js";
import "../../CssBaseline-E-zwSHrJ.js";
const p = {
  sm: e`
    width: 36px;
    height: 20px;
  `,
  md: e`
    width: 44px;
    height: 24px;
  `
}, g = {
  sm: e`
    width: 16px;
    height: 16px;
  `,
  md: e`
    width: 20px;
    height: 20px;
  `
}, s = (t) => x`
    from {
      background: ${a("tertiary")(t)};
      box-shadow: 0px 0px 0px 4px ${r(o("gray.400")(t), 0.2)};
    }
    
    to {
      background: ${o(t.$color)(t)[600]};
      box-shadow: 0px 0px 0px 4px ${r(o(t.$color)(t)[400], 0.2)};
    }
  `, w = (t) => x`
    from {
      background: ${o(t.$color)(t)[600]};
      box-shadow: 0px 0px 0px 4px ${r(o(t.$color)(t)[400], 0.2)};
    }
    
    to {
      background: ${a("tertiary")(t)};
      box-shadow: 0px 0px 0px 4px ${r(o("gray.400")(t), 0.2)};
    }
  `, $ = x`
  0% {
    left: 2px;
  }
  
  50% {
    left: 25%;
  }
  
  100% {
    left: calc(50%);
  }
`, b = x`
  0% {
    left: calc(50%);
  }

  50% {
    left: 25%;
  }
  
  100% {
    left: 2px;
  }
`;
export {
  g as buttonSizes,
  p as sizeStyles,
  b as switchOffButtonKeyFrame,
  w as switchOffContainerKeyFrame,
  $ as switchOnButtonKeyFrame,
  s as switchOnContainerKeyFrame
};
