import { j as e } from "./jsx-runtime-DmTjM30f.js";
import c from "styled-components";
import "./components/Theme/themes.es.js";
import "./palettes-Dfhobg_2.js";
import { getSpacings as p } from "./components/Theme/utils.es.js";
import "react";
import "./CssBaseline-E-zwSHrJ.js";
import "./styles-CuuuEl0j.js";
import { T as n } from "./Typography-DPoSDQ1T.js";
const d = (s) => {
  const {
    text: o,
    className: r
  } = s;
  return /* @__PURE__ */ e.jsx(
    n,
    {
      size: "sm",
      weight: "medium",
      color: "secondary",
      className: r,
      children: o
    }
  );
}, x = c.div`
  display: flex;
  width: 320px;
  flex-direction: column;;
  align-items: flex-start;
  gap: ${p("sm")};
`, b = (s) => {
  const {
    label: o,
    helperMessage: r,
    errorMessage: t,
    className: l,
    children: a
  } = s, i = t || r, m = !!t;
  return /* @__PURE__ */ e.jsxs(
    x,
    {
      className: l,
      children: [
        typeof o == "string" ? /* @__PURE__ */ e.jsx(d, { text: o }) : o,
        a,
        !!i && /* @__PURE__ */ e.jsx(
          n,
          {
            size: "sm",
            color: m ? "error" : void 0,
            children: i
          }
        )
      ]
    }
  );
};
export {
  d as F,
  b as a
};
