import { css as i } from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-Dfhobg_2.js";
import { getSpacings as p } from "../Theme/utils.es.js";
import "react";
import "../../jsx-runtime-DmTjM30f.js";
import "../../CssBaseline-E-zwSHrJ.js";
const x = {
  sm: i`
    padding: ${p("xxs")} ${p("md")};
  `,
  md: i`
    padding: ${p("xxs")} 10px;
  `,
  lg: i`
    padding: ${p("xs")} ${p("lg")};
  `
};
export {
  x as badgeSizeStyles
};
