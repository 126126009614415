import { createContext as s, useContext as c } from "react";
import { j as x } from "./jsx-runtime-DmTjM30f.js";
const e = s({}), a = (t) => {
  const {
    children: o,
    isOpen: r,
    onClose: n
  } = t;
  return /* @__PURE__ */ x.jsx(e.Provider, { value: {
    isOpen: r,
    onClose: n
  }, children: o });
}, m = () => c(e);
export {
  a as S,
  m as u
};
