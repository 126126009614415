import { createContext as l, useState as x, useContext as m } from "react";
import { j as t } from "./jsx-runtime-DmTjM30f.js";
import u from "styled-components";
import { Tab as f } from "@headlessui/react";
const r = l({
  selectedIndex: 0,
  setSelectedIndex: () => {
  }
}), p = u.div`
  display: flex;
  flex-direction: ${(e) => e.$orientation === "vertical" ? "row" : "column"};
`, T = (e) => {
  const {
    defaultIndex: s = 0,
    children: a,
    className: i,
    onChange: o,
    ...c
  } = e, [
    n,
    d
  ] = x(s);
  return /* @__PURE__ */ t.jsx(r.Provider, { value: {
    selectedIndex: n,
    setSelectedIndex: d,
    onChange: o,
    ...c
  }, children: /* @__PURE__ */ t.jsx(
    f.Group,
    {
      selectedIndex: n,
      onChange: o,
      children: /* @__PURE__ */ t.jsx(
        p,
        {
          className: i,
          $orientation: e.orientation,
          children: a
        }
      )
    }
  ) });
}, h = () => m(r);
export {
  T,
  h as u
};
