import { j as r } from "./jsx-runtime-DmTjM30f.js";
import s, { css as B } from "styled-components";
import { forwardRef as j, useRef as I } from "react";
import "./components/Theme/themes.es.js";
import "./palettes-Dfhobg_2.js";
import { getPaletteBorder as x, getSpacings as m, getRadii as P, getPaletteBackground as y, getEffects as v, getPaletteText as i, getPaletteForeground as d } from "./components/Theme/utils.es.js";
import "./CssBaseline-E-zwSHrJ.js";
import { I as w } from "./Icon-BJLMcRlb.js";
import "./components/Icon/countryVariants.es.js";
import { a as F } from "./FieldControl-Cc45bEGo.js";
const T = B`
  border-color: ${x("error.default")};
`, S = s.div`
  display: inline-flex;
  align-items: center;
  align-self: stretch;
  box-sizing: border-box;
  
  gap: ${m("md")};
  padding: ${m("md")} ${m("lg")};
  
  border-radius: ${P("md")};
  border: 1px solid ${x("primary")};
  
  background-color: ${y("primary.default")};
  
  &:focus-within, &:focus {
    box-shadow: ${v("focusRings.brand.xs")};
    border-color: ${x("brand.default")};
  }
  
  &[aria-disabled="true"] {
    background-color: ${y("disabled.default")};
  }
  
  ${(c) => c.$isInvalid ? T : ""};
`, R = s.input`
  width: 100%;
  border: none;
  background-color: transparent;
  
  color: ${i("primary.default")};
  caret-color: ${d("primary.default")};

  &:focus {
    outline: none;
  }
  
  &:disabled {
    color: ${i("disabled")};
  }

  &::-webkit-input-placeholder {
    color: ${i("placeholder")};
  }

  &::-moz-placeholder {
    color: ${i("placeholder")};
  }

  &::placeholder {
    color: ${i("placeholder")};
  }
`, b = s.div`
  display: inline-flex;
  
  color: ${d("quarterary.default")};

  &[aria-disabled="true"] {
    color: ${d("quinary.default")};
  }
`, A = s(w)`
  width: 16px;
  height: 16px;
  
  color: ${d("error.secondary")};
`, q = j(function(p, e) {
  const {
    isInvalid: l,
    className: u,
    startAdornment: t,
    endAdornment: n,
    containerProps: f,
    ...o
  } = p, {
    onClick: g,
    ...C
  } = f || {}, $ = I(null), k = (a) => {
    var h;
    g && g(a), (h = $.current) == null || h.focus();
  };
  return /* @__PURE__ */ r.jsxs(
    S,
    {
      className: u,
      $isInvalid: l,
      "aria-disabled": o.disabled,
      onClick: k,
      ...C,
      children: [
        !!t && /* @__PURE__ */ r.jsx(b, { "aria-disabled": o.disabled, children: t }),
        /* @__PURE__ */ r.jsx(
          R,
          {
            ref: (a) => {
              typeof e == "function" ? e(a) : e && (e.current = a), $.current = a;
            },
            ...o
          }
        ),
        l && /* @__PURE__ */ r.jsx(b, { children: /* @__PURE__ */ r.jsx(A, { variant: "alert-circle" }) }),
        !!n && /* @__PURE__ */ r.jsx(b, { "aria-disabled": o.disabled, children: n })
      ]
    }
  );
}), O = j(function(p, e) {
  const {
    label: l,
    helperMessage: u,
    errorMessage: t,
    className: n,
    ...f
  } = p, o = !!t;
  return /* @__PURE__ */ r.jsx(
    F,
    {
      label: l,
      helperMessage: u,
      errorMessage: t,
      className: n,
      children: /* @__PURE__ */ r.jsx(
        q,
        {
          ref: e,
          isInvalid: o,
          ...f
        }
      )
    }
  );
});
export {
  q as B,
  O as T
};
