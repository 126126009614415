import { useRef as o, useEffect as W, useMemo as k, useReducer as q, useCallback as C } from "react";
function P(u, r, i) {
  var h = this, c = o(null), l = o(0), n = o(null), a = o([]), f = o(), m = o(), b = o(u), v = o(!0);
  b.current = u;
  var x = typeof window < "u", d = !r && r !== 0 && x;
  if (typeof u != "function") throw new TypeError("Expected a function");
  r = +r || 0;
  var D = !!(i = i || {}).leading, E = !("trailing" in i) || !!i.trailing, s = "maxWait" in i, M = "debounceOnServer" in i && !!i.debounceOnServer, y = s ? Math.max(+i.maxWait || 0, r) : null;
  W(function() {
    return v.current = !0, function() {
      v.current = !1;
    };
  }, []);
  var R = k(function() {
    var A = function(t) {
      var e = a.current, g = f.current;
      return a.current = f.current = null, l.current = t, m.current = b.current.apply(g, e);
    }, p = function(t, e) {
      d && cancelAnimationFrame(n.current), n.current = d ? requestAnimationFrame(t) : setTimeout(t, e);
    }, T = function(t) {
      if (!v.current) return !1;
      var e = t - c.current;
      return !c.current || e >= r || e < 0 || s && t - l.current >= y;
    }, O = function(t) {
      return n.current = null, E && a.current ? A(t) : (a.current = f.current = null, m.current);
    }, F = function t() {
      var e = Date.now();
      if (T(e)) return O(e);
      if (v.current) {
        var g = r - (e - c.current), S = s ? Math.min(g, y - (e - l.current)) : g;
        p(t, S);
      }
    }, w = function() {
      if (x || M) {
        var t = Date.now(), e = T(t);
        if (a.current = [].slice.call(arguments), f.current = h, c.current = t, e) {
          if (!n.current && v.current) return l.current = c.current, p(F, r), D ? A(c.current) : m.current;
          if (s) return p(F, r), A(c.current);
        }
        return n.current || p(F, r), m.current;
      }
    };
    return w.cancel = function() {
      n.current && (d ? cancelAnimationFrame(n.current) : clearTimeout(n.current)), l.current = 0, a.current = c.current = f.current = n.current = null;
    }, w.isPending = function() {
      return !!n.current;
    }, w.flush = function() {
      return n.current ? O(Date.now()) : m.current;
    }, w;
  }, [D, s, r, y, E, d, x, M]);
  return R;
}
function j(u, r) {
  return u === r;
}
function z(u, r) {
  return r;
}
function G(u, r, i) {
  var h = j, c = q(z, u), l = c[0], n = c[1], a = P(C(function(m) {
    return n(m);
  }, [n]), r, i), f = o(u);
  return h(f.current, u) || (a(u), f.current = u), [l, a];
}
export {
  P as c,
  G as o
};
