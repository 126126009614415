import { j as e } from "./jsx-runtime-DmTjM30f.js";
import a from "styled-components";
import { r as b } from "./polished.esm-B6VUs5va.js";
import { useRef as j } from "react";
import { o as C } from "./index.module-CodhwjS0.js";
import { sizeStyles as v, switchOnContainerKeyFrame as I, switchOffContainerKeyFrame as S, buttonSizes as z, switchOnButtonKeyFrame as F, switchOffButtonKeyFrame as B } from "./components/Switch/styles.es.js";
import "./components/Theme/themes.es.js";
import "./palettes-Dfhobg_2.js";
import { getSpacings as m, getRadii as g, getPaletteColors as l, getPaletteBackground as d, getPaletteForeground as $, getEffects as A } from "./components/Theme/utils.es.js";
import "./CssBaseline-E-zwSHrJ.js";
import { I as E } from "./Icon-BJLMcRlb.js";
import "./components/Icon/countryVariants.es.js";
import { u as M } from "./useIsMounted-Bme5BUZ2.js";
import "@floating-ui/react-dom";
import "./styles-CuuuEl0j.js";
import { T as O } from "./Typography-DPoSDQ1T.js";
import { F as T } from "./FieldControl-Cc45bEGo.js";
const P = a.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  border: none;
  box-sizing: border-box;
  
  ${(o) => v[o.$size]};
  
  padding: ${m("xxs")};
  border-radius: ${g("full")};

  animation-timing-function: ease-in-out;
  
  animation-duration: ${(o) => o.$isAnimationEnabled ? "0.4s" : "0s"};
  
  &[aria-checked="true"] {
    animation-name: ${I};
    background: ${(o) => l(o.$color)(o)[600]};
    
    &:focus {
      box-shadow: 0px 0px 0px 4px ${(o) => b(l(o.$color)(o)[500], 0.2)};
    }
    
    &:hover:not(:focus):not(:disabled) {
      background: ${(o) => l(o.$color)(o)[700]};
    }

    &[aria-invalid="true"] {
      background-color: ${d("error.solid")};
    }
  }

  &[aria-checked="false"] {
    animation-name: ${S};
    background: ${d("tertiary")};
    
    &:focus {
      box-shadow: 0px 0px 0px 4px ${(o) => b(l("gray.400")(o), 0.2)};
    }

    &[aria-invalid="true"] {
      background-color: ${d("error.secondary")};
    }
  }
  
  &:disabled {
    background: ${d("disabled.default")};
  }
`, K = a.input`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  
  &:disabled {
    cursor: not-allowed;
  }
`, N = 400, R = a.span`
  border: none;
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  ${(o) => z[o.$size]};
  
  background-color: ${$("white")};
  border-radius: ${g("full")};
  box-shadow: ${A("shadows.sm")};
  
  animation-timing-function: ease-in-out;
  
  animation-duration: ${(o) => o.$isAnimationEnabled ? "0.3s" : "0s"};
  
  &[aria-checked="true"] {
    left: calc(50%);

    animation-name: ${F};
  }

  &[aria-checked="false"] {
    left: 2px;

    animation-name: ${B};
  }
  
`, _ = a.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`, L = a(E)`
  color: ${$("error.secondary")};
`, D = (o) => {
  const {
    size: i = "md",
    color: s = "brand",
    checked: n,
    onChange: r,
    onFocus: t,
    isInvalid: u,
    ...p
  } = o, h = j(null), y = M(), [f] = C(y, N), k = (c) => {
    r && r(c, c.target.checked);
  }, w = (c) => {
    var x;
    t && t(c), (x = h.current) == null || x.focus();
  };
  return /* @__PURE__ */ e.jsxs(
    P,
    {
      ref: h,
      $size: i,
      $color: s,
      $isAnimationEnabled: f,
      "aria-checked": n,
      "aria-invalid": u,
      disabled: p.disabled,
      children: [
        /* @__PURE__ */ e.jsx(
          K,
          {
            type: "checkbox",
            checked: n,
            onChange: k,
            onFocus: w,
            ...p
          }
        ),
        /* @__PURE__ */ e.jsx(
          R,
          {
            $size: i,
            $isAnimationEnabled: f,
            "aria-checked": n,
            children: u && /* @__PURE__ */ e.jsx(_, { children: /* @__PURE__ */ e.jsx(
              L,
              {
                variant: "alert-circle",
                width: 14,
                height: 14
              }
            ) })
          }
        )
      ]
    }
  );
}, U = a.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  gap: ${m("xxs")};
`, Y = (o) => {
  const {
    label: i,
    helperMessage: s,
    errorMessage: n
  } = o, r = !!n, t = n || s;
  return /* @__PURE__ */ e.jsxs(U, { children: [
    typeof i == "string" ? /* @__PURE__ */ e.jsx(T, { text: i }) : i,
    !!t && /* @__PURE__ */ e.jsx(
      O,
      {
        size: "sm",
        color: r ? "error" : void 0,
        children: t
      }
    )
  ] });
}, q = a.div`
  display: flex;
  width: 344px;
  
  gap: ${m("md")};
`, lo = (o) => {
  const {
    label: i,
    helperMessage: s,
    errorMessage: n,
    ...r
  } = o, t = !!n;
  return /* @__PURE__ */ e.jsxs(q, { children: [
    /* @__PURE__ */ e.jsx(
      D,
      {
        isInvalid: t,
        ...r
      }
    ),
    /* @__PURE__ */ e.jsx(
      Y,
      {
        label: i,
        helperMessage: s,
        errorMessage: n
      }
    )
  ] });
};
export {
  D as B,
  lo as S,
  Y as a
};
