import { css as e } from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-Dfhobg_2.js";
import { getPaletteBorder as o, getSpacings as t } from "../Theme/utils.es.js";
import "react";
import "../../jsx-runtime-DmTjM30f.js";
import "../../CssBaseline-E-zwSHrJ.js";
const c = (i, r) => {
  switch (i) {
    case "horizontal":
      return e`
        width: 100%;
        
        border-top: 1px solid ${o("secondary")};
        margin: ${t(r)} 0 ${t(r)} 0;
      `;
    case "vertical":
      return e`
        height: 100%;

        border-left: 1px solid ${o("secondary")};
        margin: 0 ${t(r)} 0 ${t(r)};
      `;
    default:
      return e``;
  }
};
export {
  c as getDividerStyles
};
