import { createContext as o, createRef as n, useRef as s, useContext as a } from "react";
import { j as c } from "./jsx-runtime-DmTjM30f.js";
const e = o({
  headerRef: n()
}), x = (t) => {
  const r = s(null);
  return /* @__PURE__ */ c.jsx(e.Provider, { value: {
    headerRef: r
  }, children: t.children });
}, i = () => a(e);
export {
  x as T,
  i as u
};
