import { j as s } from "../../jsx-runtime-DmTjM30f.js";
import { B as c } from "../../BaseSelect-DXLZ9TIN.js";
import { S as B, a as I } from "../../BaseSelect-DXLZ9TIN.js";
import "../../styles-CuuuEl0j.js";
import "../../Typography-DPoSDQ1T.js";
import { a as i } from "../../FieldControl-Cc45bEGo.js";
const d = (t) => {
  const {
    label: o,
    helperMessage: r,
    errorMessage: e,
    className: a,
    ...l
  } = t, m = !!e;
  return /* @__PURE__ */ s.jsx(
    i,
    {
      label: o,
      helperMessage: r,
      errorMessage: e,
      className: a,
      children: /* @__PURE__ */ s.jsx(
        c,
        {
          isInvalid: m,
          ...l
        }
      )
    }
  );
};
export {
  c as BaseSelect,
  B as SelectItem,
  I as SelectItemLabel,
  d as default
};
