import { j as e } from "../../jsx-runtime-DmTjM30f.js";
import o from "styled-components";
import { B as l } from "../../TextField-vJ13tb4d.js";
import { I as a } from "../../Icon-BJLMcRlb.js";
import "react";
import "../../components/Icon/countryVariants.es.js";
import "../../components/Theme/themes.es.js";
import "../../palettes-Dfhobg_2.js";
import { getBreakpointMediaQuery as s, getPaletteForeground as t } from "../../components/Theme/utils.es.js";
import "../../CssBaseline-E-zwSHrJ.js";
const d = o(l)`
  
  ${s("max", "lg")} {
    width: 100%;
  }
`, c = o(a)`
  color: ${t("quarterary.default")}
`, m = o(a)`
  color: ${t("quinary.default")};
  cursor: pointer;
  
  visibility: ${(r) => r.$isHidden ? "hidden" : "visible"};
  
  &:hover {
    color: ${t("quinary.hover")};
  }
`, F = (r) => {
  const {
    onClearIconClick: n,
    ...i
  } = r;
  return /* @__PURE__ */ e.jsx(
    d,
    {
      startAdornment: /* @__PURE__ */ e.jsx(
        c,
        {
          variant: "search-lg",
          width: 20,
          height: 20
        }
      ),
      endAdornment: /* @__PURE__ */ e.jsx(
        m,
        {
          variant: "x-circle",
          color: "quinary",
          width: 20,
          height: 20,
          onClick: n,
          $isHidden: !i.value
        }
      ),
      ...i
    }
  );
};
export {
  F as default
};
