import { I } from "../../Icon-BJLMcRlb.js";
import { j as t } from "../../jsx-runtime-DmTjM30f.js";
import { useMemo as m, Fragment as c, Suspense as p } from "react";
import u from "./countryVariants.es.js";
const x = (n) => {
  const {
    code: o,
    ...e
  } = n, s = m(() => {
    const r = u[o];
    return r || c;
  }, [o]);
  return /* @__PURE__ */ t.jsx(p, { children: /* @__PURE__ */ t.jsx(s, { ...e }) });
};
export {
  x as CountryIcon,
  I as default
};
