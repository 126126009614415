import { j as e } from "../../jsx-runtime-DmTjM30f.js";
import u from "styled-components";
import { Tab as $ } from "@headlessui/react";
import { Fragment as j } from "react";
import { tabSizeStyles as S, tabVariantStyles as v, tabOrientationStyles as P, tabListVariantStyles as L, tabListOrientationStyles as z } from "./styles.es.js";
import "../Theme/themes.es.js";
import "../../palettes-Dfhobg_2.js";
import { getPaletteColors as C, getPaletteBase as B, getSpacings as b, getPaletteText as F } from "../Theme/utils.es.js";
import "../../CssBaseline-E-zwSHrJ.js";
import "../../styles-CuuuEl0j.js";
import { T as w } from "../../Typography-DPoSDQ1T.js";
import { u as x } from "../../hooks-BeV7zINe.js";
import { T as lt } from "../../hooks-BeV7zINe.js";
import { B as W } from "../../BaseSelect-DXLZ9TIN.js";
import "../../FieldControl-Cc45bEGo.js";
import "react-phone-number-input";
import { useMediaQuery as k } from "../Theme/hooks.es.js";
const I = (t) => /* @__PURE__ */ e.jsx(
  w,
  {
    color: "none",
    weight: "semibold",
    ...t
  }
), O = u.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;

  color: ${C("gray.500")};
  background-color: ${B("transparent")};
  padding: ${b("md")} ${b("lg")};
  gap: ${b("md")};
  
  ${(t) => S[t.$size]};
  ${(t) => v[t.$variant]};
  ${(t) => P[t.$orientation]};
  
  &:hover {
    cursor: pointer;
  }
  
  &:disabled {
    cursor: not-allowed;
    
    color : ${F("disabled")};
  }
  
`, H = (t) => {
  const {
    children: o,
    size: n = "md",
    variant: i = "lined",
    color: r = "brand",
    orientation: a = "horizontal",
    ...l
  } = t;
  return /* @__PURE__ */ e.jsx($, { as: j, children: (d) => /* @__PURE__ */ e.jsx(
    O,
    {
      $size: n,
      $variant: i,
      $color: r,
      $orientation: a,
      $isSelected: d.selected,
      ...l,
      children: typeof o == "string" ? /* @__PURE__ */ e.jsx(I, { children: o }) : o
    }
  ) });
}, M = (t) => {
  const {
    index: o,
    onClick: n,
    ...i
  } = t, {
    variant: r,
    orientation: a,
    color: l,
    size: d,
    setSelectedIndex: m
  } = x(), p = (h) => {
    m(o), n && n(h);
  };
  return /* @__PURE__ */ e.jsx(
    H,
    {
      variant: r,
      color: l,
      size: d,
      orientation: a,
      onClick: p,
      ...i
    }
  );
}, V = u($.List)`
  display: flex;
  align-items: center;
  overflow: auto;
  scrollbar-width: thin;
  
  gap: ${b("xs")};
  
  width: ${(t) => t.$isFullWidth && t.$orientation === "horizontal" ? "100%" : "initial"};
  
  > * {
    flex: ${(t) => t.$isFullWidth ? 1 : "none"};
  }
  
  ${(t) => L[t.$variant]};
  ${(t) => z[t.$orientation]};
`, E = (t) => {
  const {
    children: o,
    variant: n = "lined",
    isFullWidth: i,
    orientation: r = "horizontal",
    ...a
  } = t;
  return /* @__PURE__ */ e.jsx(
    V,
    {
      $variant: n,
      $isFullWidth: i,
      $orientation: r,
      ...a,
      children: o
    }
  );
}, Q = u(E)`
  display: ${(t) => t.$isHidden ? "none" : "flex"};
`, nt = (t) => {
  const {
    tabs: o,
    ...n
  } = t, {
    variant: i,
    orientation: r,
    isFullWidth: a,
    selectedIndex: l,
    setSelectedIndex: d,
    onChange: m
  } = x(), p = k("mobile"), h = o.map((c, s) => ({
    value: s,
    label: c.label,
    disabled: c.disabled
  })), g = (c, s) => {
    const {
      label: y,
      ...T
    } = c;
    return /* @__PURE__ */ e.jsx(
      M,
      {
        index: s,
        ...T,
        children: y
      },
      s
    );
  }, f = (c, s) => {
    d(s), m && m(s);
  };
  return /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
    /* @__PURE__ */ e.jsx(
      Q,
      {
        $isHidden: p,
        variant: i,
        isFullWidth: a,
        orientation: r,
        ...n,
        children: o.map(g)
      }
    ),
    p && /* @__PURE__ */ e.jsx(
      W,
      {
        value: l,
        options: h,
        onChange: f
      }
    )
  ] });
}, it = (t) => /* @__PURE__ */ e.jsx($.Panel, { ...t }), rt = (t) => /* @__PURE__ */ e.jsx($.Panels, { ...t });
export {
  M as Tab,
  I as TabLabel,
  nt as TabList,
  it as TabPanel,
  rt as TabPanelList,
  lt as default,
  x as useTabControl
};
