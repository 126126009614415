import { j as i } from "../../jsx-runtime-DmTjM30f.js";
import a from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-Dfhobg_2.js";
import { getSpacings as e, getBreakpointMediaQuery as d } from "../Theme/utils.es.js";
import "react";
import "../../CssBaseline-E-zwSHrJ.js";
import "../../styles-CuuuEl0j.js";
import { T as l } from "../../Typography-DPoSDQ1T.js";
const s = a.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;

  padding: ${e("3xl")};
  padding-bottom: ${e("4xl")};
  gap: ${e("2xl")};
  
  ${d("max", "lg")} {
    padding-left: 0;
    padding-right: 0;
    
    padding-bottom: ${(t) => t.$removeBottomPaddingInMobile ? 0 : e("4xl")(t)};
  }
`, j = (t) => {
  const {
    children: o,
    className: n,
    removeBottomPaddingInMobile: r = !1
  } = t;
  return /* @__PURE__ */ i.jsx(
    s,
    {
      className: n,
      $removeBottomPaddingInMobile: r,
      children: o
    }
  );
}, m = (t) => /* @__PURE__ */ i.jsx(
  l,
  {
    variant: "display",
    size: "xs",
    weight: "semibold",
    ...t
  }
), g = a.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  
  gap: ${e("md")};

  ${d("max", "lg")} {
    padding-left: ${e("2xl")};
    padding-right: ${e("2xl")};
  }
`, v = (t) => {
  const {
    title: o,
    children: n,
    className: r
  } = t;
  return /* @__PURE__ */ i.jsxs(g, { className: r, children: [
    /* @__PURE__ */ i.jsx(m, { children: o }),
    n
  ] });
};
export {
  v as PageContentHeader,
  m as PageContentTitle,
  j as default
};
